import React from 'react'
import { Link, graphql } from 'gatsby'
import Seo from '../components/seo'
import Header from '../components/header'
import BackgroundImage from 'gatsby-background-image'
import FormContact from '../components/form_contact'
import Footer from '../components/footer'
import Copyright from '../components/copyright'
import Img from "gatsby-image"
import NavMobile from "../components/nav_mobile"

export default ({ data }) => {

    const entry = data.markdownRemark
    return (
        <div className="page">
            <NavMobile />
            <Seo title={entry.frontmatter.page_title} description={entry.frontmatter.meta_description} image="" pathname={entry.fields.slug} />
            <Header />
            <BackgroundImage className="layout title__48 banner__background" fluid={entry.frontmatter.background.childImageSharp.fluid} backgroundColor={`#ffffff`}>
                <div className="layout__container">
                    <div className="layout__title banner center">
                        <h1>{entry.frontmatter.heading}</h1>
                    </div>
                </div>
            </BackgroundImage>
            <div className="layout">
                <div className="layout__container">
                    <div className="layout__wide">
                        <nav className="breadcrumb breadcrumb__top">
                            <p><Link to="/">Home</Link> &nbsp;&rsaquo;&nbsp; <strong>Contact</strong></p>
                        </nav>
                    </div>
                </div>
            </div>
            <div className="layout body">
                <div className="layout__container">
                    <div className="layout__wide text">
                        <div className="grid grid__2 grid__gap--48">
                            <div>
                                <div dangerouslySetInnerHTML={{ __html: entry.html }} />
                                <figure className="text__img">
                                    <Img fluid={entry.frontmatter.image.childImageSharp.fluid} alt={entry.frontmatter.heading} /> 
                                </figure>
                            </div>
                            <div>
                                <FormContact />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
            <Copyright />
        </div>
    )
}

export const query = graphql`
    query($slug: String!) {
        markdownRemark( fields: { slug: { eq: $slug } }) {
            frontmatter {
                title
                page_title
                meta_description
                background {
                    childImageSharp {
                        fluid(maxWidth: 1024) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
                heading
                image {
                    childImageSharp {
                        fluid(maxWidth: 1024) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
            fields {
                slug
            }
            html
        }
    }
`